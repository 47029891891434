import router from '@/router';

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },  
  created() {
      // sessionStorage.setItem('state', 0);
      this.user.status = 0;
      
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })
  
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()

      })
    },
  methods: {
      updateAvailable(event) {
        this.registration = event.detail
        // this.updateExists = true
        // this.$store.patchState({ updateExists: true });

        if(this.user.status == 0) {
          this.refreshApp();
        } 
      },
  
      refreshApp() {
        if (!this.registration || !this.registration.waiting) return
        // this.updateExists = false;
        // this.$store.patchState({ updateExists: false });
        
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })

        router.replace('/');
      },
    },
  }