
import AppHeader from "@/components/AppHeader.vue";
import SidebarMenuAkahon from "@/components/sidebar/AkahonSideMenu.vue";
import update from "@/mixins/update";

import { useSettings } from "@/store/user";

import { watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: "App",
  setup() {
    const user = useSettings();

    const route = useRoute()

    // Watch route path changes
    watch(() => route.path, () => {
      user.updateData()  // Call the method from the store
    });

    return { user };
  },
  data() {
    return {
      email: null,
      password: null,
      errors: [],
    };
  },
  mixins: [update],
  components: {
    AppHeader,
    SidebarMenuAkahon,
  },
  methods: {
  },
};
