<template>
    <div></div>
</template>

<script>

import { useSettings } from '@/store/user';

export default {
    name: 'app-nav',
    setup() {
        return {
            user: useSettings()
        }
    },
    methods: {
        handleLogout() {
            this.user.userLogout();
        },
    },
};
</script>

<style>
.navbar-right {
    margin-right: 0px !important
}

.log {
    margin: 5px 10px 0 0;
}
</style>