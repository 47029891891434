import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import './assets/css/index.css'
import './assets/css/fonts.css'

import store from "@/store/store.js";
import router from "@/router";

// favicon
import { useFavicon } from '@vueuse/core'
const icon = useFavicon();
icon.value = './favicon.ico'

// google-tag
import VueGtag from "vue-gtag";

// i18
const i18n = createI18n({
  locale: 'da',
  fallbackLocale: 'en',
  messages: {
    en: {
      i18n: {
        chooseLang: 'Choose language:',
        username: 'Email:',
        password: 'Password:',
        login: {
          header: 'Login',
          username: 'Email',
          password: 'Password',
          login: 'Login',
          logout: 'Logout',
          forgotPassword: 'Forgot password?',
          register: 'Register',
          error: 'Invalid email or password',
        }
      },
      menu: {
        overview: {
          menuitem: 'Dashboard',
          page: {
            header: 'Dashboard',
            totalsOverview: {
              date: 'Date',
              weekday: 'Weekday',
              actions: 'Actions',
              add: 'Add new daily total',
              edit: 'Edit daily total',
            }
          },
          subpages: {
            editTotals: {
              header: 'Edit daily total',
              back: 'Back to overview',
            }
          }
        },
        statistic: {
          menuitem: 'Statistic',
          page: {
            header: 'Statistic',
          }
        },
        settings: {
          menuitem: 'Settings',
          page: {
            header: 'Settings',
            userOverview:  {
              header : 'User overview',
              add: 'Add new employee',
              edit: 'Edit employee',
              id: 'ID',
              userName: 'User name',
              email: 'Email',
              userLevel: 'User level',
              actions: 'Actions',
            },
            restaurantOverview: {
              header : 'Restaurant overview',
              add: 'Add new restaurant',
              edit: 'Edit restaurant',
              id: 'ID',
              name: 'Name',
              managerId: 'Manager ID',
              assignedProducts: 'Assigned products',
              actions: 'Actions',
            },
            productOverview: {
              header : 'Product overview',
              add: 'Add new product',
              edit: 'Edit product',
              id: 'ID',
              name: 'Name',
              category: 'Category',
              actions: 'Actions',
            },
          }
        },
        user_profile: {
          menuitem: 'User profile',
          page: {
            header: 'User profile',
            userRole: 'User role',
            userName: 'User name',
            userCompany : 'Company',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    },
    da: {
      i18n: {
        chooseLang: 'Vælg sprog:',
        username: 'Email:',
        password: 'Adgangskode:',
        login: {
          header: 'Login',
          username: 'Email',
          password: 'Adgangskode',
          login: 'Login',
          logout: 'Log ud',
          forgotPassword: 'Glemt adgangskode?',
          register: 'Registrer',
          error: 'Ugyldig email eller adgangskode',
        }
      },
      menu: {
        overview: {
          menuitem: 'Overblik',
          page: {
            header: 'Overblik',
            totalsOverview: {
              date: 'Dato',
              weekday: 'Ugedag',
              actions: 'Handlinger',
              add: 'Tilføj ny daglig total',
              edit: 'Rediger daglig total',
            }
          },
          subpages: {
            editTotals: {
              header: 'Rediger daglig total',
            }
          }
        },
        statistic: {
          menuitem: 'Statistik',
          page: {
            header: 'Statistik',
          }
        },
        settings: {
          menuitem: 'Indstillinger',
          page: {
            header: 'Indstillinger',
            userOverview:  {
              header : 'Brugeroversigt',
              add: 'Tilføj ny medarbejder',
              edit: 'Rediger medarbejder',
              id: 'ID',
              userName: 'Brugernavn',
              email: 'Email',
              userLevel: 'Brugerniveau',
              actions: 'Handlinger',
            },
            restaurantOverview: {
              header : 'Restaurantoversigt',
              add: 'Tilføj ny restaurant',  
              edit: 'Rediger restaurant',
              id: 'ID',
              name: 'Navn',
              managerId: 'Manager ID',
              assignedProducts: 'Tildelte produkter',
              actions: 'Handlinger',
            },
            productOverview: {
              header : 'Produktoversigt',
              add: 'Tilføj nyt produkt',
              edit: 'Rediger produkt',
              id: 'ID',
              name: 'Navn',
              category: 'Kategori',
              actions: 'Handlinger',
            },
          }
        },
        user_profile: {
          menuitem: 'Min profil',
          page: {
            header: 'Min profil',
            userRole: 'Brugerrolle',
            userName: 'Brugernavn',
            userCompany : 'Firma',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    },
    de: {
      i18n: {
        chooseLang: 'Sprache wählen:',
        username: 'Email:',
        password: 'Passwort:',
        login: {
          header: 'Anmeldung',
          username: 'Email',
          password: 'Passwort',
          login: 'Anmeldung',
          logout: 'Abmelden',
          forgotPassword: 'Passwort vergessen?',
          register: 'Registrieren', 
          error: 'Ungültige E-Mail oder Passwort',
        }
      },
      menu: {
        overview: {
          menuitem: 'Armaturenbrett',
          page: {
            header: 'Armaturenbrett',
            totalsOverview: {
              date: 'Datum',
              weekday: 'Wochentag',
              actions: 'Aktionen',
              add: 'Neuen Tagesgesamtbetrag hinzufügen',
              edit: 'Tagesgesamtbetrag bearbeiten',
            }
          },
          subpages: {
            editTotals: {
              header: 'Tagesgesamtbetrag bearbeiten',
            },
          }
        },
        statistic: {
          menuitem: 'Statistik',
          page: {
            header: 'Statistik',
          }
        },
        settings: {
          menuitem: 'Einstellungen',
          page: {
            header: 'Einstellungen',
            userOverview:  {
              header : 'Benutzerübersicht',
              add: 'Neuen Mitarbeiter hinzufügen',
              edit: 'Mitarbeiter',
              id: 'ID',
              userName: 'Benutzername',
              email: 'Email',
              userLevel: 'Benutzerebene',
              actions: 'Aktionen',
            },
            restaurantOverview: {
              header : 'Restaurantübersicht',
              add: 'Neues Restaurant hinzufügen',
              edit: 'Restaurant bearbeiten',
              id: 'ID',
              name: 'Name',
              managerId: 'Manager ID',
              assignedProducts: 'Zugewiesene Produkte',
              actions: 'Aktionen',
            },
            productOverview: {
              header : 'Produktübersicht',
              add: 'Neues Produkt hinzufügen',
              edit: 'Produkt bearbeiten',
              id: 'ID',
              name: 'Name',
              category: 'Kategorie',
              actions: 'Aktionen',
            },
          }
        },
        user_profile: {
          menuitem: 'Benutzerprofil',
          page: {
            header: 'Benutzerprofil',
            userRole: 'Benutzerrolle',
            userName: 'Benutzername',
            userCompany : 'Firma',
            userRestaurant : 'Restaurant',
            userEmail : 'Email',
          }
        },
      }
    }
  }
})

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueGtag, { 
  appName: process.env.VUE_APP_GTAG_NAME,
  pageTrackerScreenviewEnabled: true,
  config: { id: process.env.VUE_APP_GTAG }}, router)
app.use(i18n)

router.isReady().then(() => {
  app.mount("#app");
});