<template>
    <h1>{{ $t('menu.overview.page.header') }}</h1>
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.overview.page.totalsOverview.date') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.overview.page.totalsOverview.weekday') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.overview.page.totalsOverview.actions') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in user.totals" :key="u.p_id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ u.date }}
                    </th>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ u.weekday }}
                    </th>
                    <td class="px-6 py-4">
                        <button class="appButton" @click="$router.push({ name: 'edit-totals', params: { id: u.restaurant_p_id, date: u.date } })">{{ $t('menu.overview.page.totalsOverview.edit') }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <button class="appButton mt-2" @click="user.addEmployee()">{{ $t('menu.overview.page.totalsOverview.add') }}</button>
    </div>
</template>

<script>
import { useSettings } from '@/store/user'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    setup() {
        const user = useSettings();

        return { user }
    },
    components: {
        
    },
    methods: {

    }
}
</script>