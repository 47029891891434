<template>
    <h1 id="page-title" class="content__title">{{ $t('menu.user_profile.page.header') }}</h1>

    <div>
        <div class="content__list-item">
            {{ $t('menu.user_profile.page.userName') }} : {{ user.userName }}
        </div>
        <div class="content__list-item">
            {{ $t('menu.user_profile.page.userEmail') }} : {{ user.userEmail }}
        </div>
        <div class="content__list-item">
            {{ $t('menu.user_profile.page.userRole') }} : {{ user.userRole }}
        </div>
        <div class="content__list-item">
            {{ $t('menu.user_profile.page.userCompany') }} : {{ user.userCompany }}
        </div>
        <div class="content__list-item">
            {{ $t('menu.user_profile.page.userRestaurant') }} : {{ user.userRestaurant }}
        </div>
    </div>
</template>

<script>
import { useSettings } from '@/store/user'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    setup() {
        const user = useSettings();

        return { user }
    },
    components: {
        
    },
    methods: {

    }
}
</script>