<template>
    <h1>{{ $t('menu.overview.subpages.editTotals.header') }}</h1>
    <button class="appButton" @click="$router.push('/overview')">{{ $t('menu.overview.subpages.editTotals.back') }}</button>

    <div v-for="t in user.dailyTotals" :key="t.p_id">
        {{ t.date }}
        {{ t.weekday }}
        {{ t.total }}
        {{ t.category_p_id }}
        {{ t.product_p_id }}
        {{ t.value }}
        {{ t.usage_value }}
    </div>

</template>
<style></style>
<script>
import { useSettings } from '@/store/user'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    data () {
        return {
            date: this.$route.params.date,
            id: this.$route.params.id
        }
    },
    setup() {
        const user = useSettings();

        return { user }
    },
    created() {
        this.user.dailyTotals = [];
    },
    mounted() {
        console.log(this.date)
        console.log(this.id)

        this.user.getDailyTotals(this.$route.params.id, this.$route.params.date)
    },
    components: {
        
    },
    methods: {

    }
}
</script>