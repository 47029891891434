import { defineStore } from 'pinia'

// quick and dirty bomb
const debugMode = true;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      userEmail: null,
      userName: null,
      userRole: null,
      userCompany: null,
      userLogo: 'https://naestvedcity.dk/wp-content/uploads/2021/03/image-100.png',
      userRestaurant: null,
      users: [],
      restaurants: [],
      products: [],
      totals: [],
      dailyTotals: [],
      status: 0,
      updateExists: false,
      errors: [],
    }
  },
  actions: {
    async userLogin(email,password) {
      const res = await fetch(this.backendApi + "/api/users");
      const data = await res.json();
            
      data.result.forEach(x => {
        if(x.email === email && x.user_password === password){
          this.isAuthenticated = true;
          this.userEmail = x.email;
          this.userName = x.user_name;
          this.userRole = x.user_level;
          this.userCompany = x.company_id;
          this.userRestaurant = x.restaurant_id;

          this.setSession(1);
          this.updateData(x);
        }
      })

      if(!this.isAuthenticated){
        this.errors.push("Login failed");
      }
    },
    async updateData(){
      this.getUsers(this.userCompany);
      this.getRestaurants(this.userCompany);
      this.getProducts(this.userCompany);
      this.getTotals(this.userRestaurant,"");
    },
    async updateOnRouteChange() {
      console.log('Route has changed!')
    },
    async userLogout() {
      this.isAuthenticated = false;
      this.userEmail = null;
      this.userName = null;
      this.userAddress = null;
      this.userPhone = null;
      this.userImage = null;
      this.userRole = null;
      this.userCompany = null;
      this.userRestaurant = null;
      this.users = [];
      this.errors = [];

      this.setSession(0);

    },
    async setSession(value) {
      this.status = value;      
    },
    async userForgotPassword(email) {
      const res = await fetch(this.backendApi + "/api/users");
      const data = await res.json();
            
      data.result.forEach(x => {
          if(x.email === email){
            alert("Password sent to email");
          }
        }
      )
    },
    async editUser(id) {
      console.log(id)
    },
    async addEmployee() {
        console.log('addEmployees')
    },
    async getRestaurants(id) {
      const res = await fetch(this.backendApi + "/api/restaurants/" + id);
      const data = await res.json();

      this.restaurants = [];
            
      data.result.forEach(x => {
        this.restaurants.push(x);
      })
    },
    async getProducts(id) {
      const res = await fetch(this.backendApi + "/api/products/" + id);
      const data = await res.json();

      this.products = [];

      data.result.forEach(x => {
          this.products.push(x);
      }) 
    },
    async getUsers(id) {
      const res = await fetch(this.backendApi + "/api/users/" + id);
      const data = await res.json();

      this.users = [];
            
      data.result.forEach(x => {
        this.users.push(x);
      })
    },
    async getTotals(id,date) {
      const res = await fetch(this.backendApi + "/api/totals/" + id + "/" + date);
      const data = await res.json();

      this.totals = [];

      data.result.forEach(x => {
        this.totals.push(x);
      })
    },
    async getDailyTotals(id,date) {
      const res = await fetch(this.backendApi + "/api/totals/" + id + "/" + date);
      const data = await res.json();

      this.dailyTotals = [];

      data.result.forEach(x => {
        this.dailyTotals.push(x);
      })
    }
  },
  persist: true,
})