<template>
  <div class="sidebar" :class="isOpened ? 'open' : ''" :style="cssVars">
      <div class="logo-details" style="margin: 6px 8px 0 14px;">
        <div class="logo_name">
        <!-- BASTA -->
        <div :class="isOpened ? 'visible m-4' : 'invisible'"><img class="" :src="user.userLogo"></div>
      </div>
      <i class="bx" :class="isOpened ? 'bx-menu-alt-right' : 'bx-menu'" id="btn" @click="isOpened = !isOpened" />
    </div>

    <div v-show="user.isAuthenticated"
      style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
        <div id="my-scroll" style="">
        <ul class="nav-list" style="overflow: visible;">
          <li v-if="isSearch" @click="isOpened = true">
            <i class="bx bx-search" />
            <input type="text" :placeholder="searchPlaceholder"
              @input="$emit('search-input-emit', $event.target.value)">
            <span class="tooltip">{{ searchTooltip }}</span>
          </li>

          <span v-for="(menuItem, index) in menuItems" :key="index">
            <li>
              <router-link :to="menuItem.link">
                <i class="bx" :class="menuItem.icon || 'bx-square-rounded'" />
                <span class="links_name">{{ $t('menu.' + menuItem.i18n + '.menuitem') }}</span>
              </router-link>
              <span class="tooltip">{{ menuItem.tooltip || menuItem.name }}</span>
            </li>
          </span>
        </ul>
      </div>

      <div :class="isOpened ? 'locale-changer ml-2' : 'locale-changer m-2'">
          <div :class="isOpened ? 'ml-2 text-black' : 'text-black'">
                <span :class="isOpened ? 'show' : 'hidden'">{{ $t('i18n.chooseLang') }}</span>
                <select :class="isOpened ? 'show ml-2 p-1' : 'ml-0 p-1'" v-model="$i18n.locale">
                    <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                </select>
          </div>
      </div>
      <button :class="isOpened ? 'appAuth0Button ml-2 m-2' : 'appAuth0ButtonSmall'" v-show="user.isAuthenticated" @click="handleLogout()"><span :class="isOpened ? 'show' : 'hidden'">{{ $t('i18n.login.logout') }}</span><span :class="isOpened ? 'hidden' : 'bx bx-log-out-circle'"></span></button>
    </div>
  </div>
</template>

<script>
import { useSettings } from '@/store/user';

export default {
  name: 'SidebarMenuAkahon',
  setup() {
      return {
          user: useSettings()
      }
  },
  props: {
    //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: true,
    },
    menuTitle: {
      type: String,
      default: 'Akahon',
    },
    menuLogo: {
      type: String,
      default: '',
    },
    menuIcon: {
      type: String,
      default: 'bxl-c-plus-plus',
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: '250px'
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: '65px'
    },
    //! Menu items
    menuItems: {
      type: Array,
      default: () => [
        {
          link: '/',
          name: 'Overblik',
          tooltip: 'Overblik',
          icon: 'bx-grid-alt',
          i18n: 'overview'
        },
        {
          link: '/statistic',
          name: 'Statistik',
          tooltip: 'Statistik',
          icon: 'bx-stats',
          i18n: 'statistic'
        },
        {
          link: '/settings',
          name: 'Indstillinger',
          tooltip: 'Indstillinger',
          icon: 'bx-check-shield',
          i18n: 'settings'
        },
        {
          link: '/profile',
          name: 'Min bruger',
          tooltip: 'Min bruger',
          icon: 'bx-user',
          i18n: 'user_profile'
        }
      ],
    },
    //! Search
    isSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    searchTooltip: {
      type: String,
      default: 'Search',
    },
    //! Profile detailes
    profileImg: {
      type: String,
      default: '',
    },
    profileName: {
      type: String,
      default: '',
    },
    profileRole: {
      type: String,
      default: '',
    },
    isExitButton: {
      type: Boolean,
      default: true,
    },
    isLoggedIn: {
      type: Boolean,
      default: true,
    },
    //! Styles
    bgColor: {
      type: String,
      default: '#e6e6e6',
    },
    secondaryColor: {
      type: String,
      default: '#e6e6e6',
    },
    homeSectionColor: {
      type: String,
      default: '#e6e6e6',
    },
    logoTitleColor: {
      type: String,
      default: '#585858',
    },
    iconsColor: {
      type: String,
      default: '#585858',
    },
    itemsTooltipColor: {
      type: String,
      default: '#e6e6e6',
    },
    searchInputTextColor: {
      type: String,
      default: '#585858',
    },
    menuItemsHoverColor: {
      type: String,
      default: '#d3d3d3',
    },
    menuItemsTextColor: {
      type: String,
      default: '#585858',
    },
    menuFooterTextColor: {
      type: String,
      default: '#585858',
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  mounted() {
    this.isOpened = this.isMenuOpen
  },
  computed: {
    cssVars() {
      return {
        // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
        '--bg-color': this.bgColor,
        '--secondary-color': this.secondaryColor,
        '--home-section-color': this.homeSectionColor,
        '--logo-title-color': this.logoTitleColor,
        '--icons-color': this.iconsColor,
        '--items-tooltip-color': this.itemsTooltipColor,
        '--serach-input-text-color': this.searchInputTextColor,
        '--menu-items-hover-color': this.menuItemsHoverColor,
        '--menu-items-text-color': this.menuItemsTextColor,
        '--menu-footer-text-color': this.menuFooterTextColor,
      }
    },
  },
  watch: {
    isOpened() {
      window.document.body.style.paddingLeft = this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
    }
  },
  methods: {
        handleLogout() {
            this.user.userLogout();
        },
    },
}
</script>

<style>
/* Google Font Link */
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

a.router-link-active.router-link-exact-active {
    background: #d3d3d3;
}

a.router-link-active.router-link-exact-active > i {
    background: #d3d3d3;
}

a.router-link-active.router-link-exact-active > .bx:before {
  color: #585858;
}

a.router-link-active.router-link-exact-active > .links_name {
    color: #585858 !important;
}

.menu-logo {
  width: 30px;
  margin: 0 10px 0 10px;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  /* overflow-y: auto; */
  width: 65px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: var(--items-tooltip-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: var(--secondary-color);
  color: var(--icons-color);
}

.sidebar.open .bx-search:hover {
  background: var(--secondary-color);
  color: var(--icons-color);
}

.sidebar .bx-search:hover {
  background: var(--menu-items-hover-color);
  color: var(--bg-color);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 85%;
  border-radius: 10px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
  margin-left: 5px;
}

.sidebar li a:hover {
  background: var(--menu-items-hover-color);
}

.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #585858;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 65px;
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar div img {
  /* height: 45px; */
  width: 80px;
  object-fit: cover;
  /* border-radius: 6px; */
  margin-right: 10px;
}

.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
  text-transform: capitalize;
}

.sidebar div.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  opacity: 0;
}

.sidebar.open .profile:hover #log_out {
  opacity: 1;
}

.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: #ffffff;
}

.sidebar .profile #log_out:hover {
  color:#ffffff;
}

.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 65px;
  width: calc(100% - 65px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.my-scroll-active {
  overflow-y: auto;
}

#my-scroll {
  overflow-y: auto;
  height: calc(100% - 60px);
}

#my-scroll::-webkit-scrollbar {
  display: none;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>