<template>
    <h1>{{ $t('menu.statistic.page.header') }}</h1>
</template>

<script>
import { useSettings } from '@/store/user'
// import { storeToRefs } from 'pinia'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    setup() {
        const user = useSettings();

        // const {  } = storeToRefs(user)

        return { user }
    },
    components: {
        
    },
    methods: {

    }
}
</script>