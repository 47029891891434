<template>
    <h1>{{ $t('menu.settings.page.header') }}</h1>

    <h2>{{ $t('menu.settings.page.userOverview.header') }}</h2>
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.userOverview.id') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.userOverview.userName') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.userOverview.email') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.userOverview.userLevel') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.userOverview.actions') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in user.users" :key="u.p_id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ u.p_id }}
                    </th>
                    <td class="px-6 py-4">
                        {{ u.user_name }}
                    </td>
                    <td class="px-6 py-4">
                        {{ u.email }}
                    </td>
                    <td class="px-6 py-4">
                        {{ toogleAdminName(u.user_level) }}
                    </td>
                    <td class="px-6 py-4">
                        <button class="appButton" @click="user.editUser(u.p_id)">{{ $t('menu.settings.page.userOverview.edit') }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <button class="appButton mt-2" @click="user.addEmployee()">{{ $t('menu.settings.page.userOverview.add') }}</button>
    </div>

    <h2>{{ $t('menu.settings.page.restaurantOverview.header') }}</h2>
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.restaurantOverview.id') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.restaurantOverview.name') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.restaurantOverview.managerId') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.restaurantOverview.assignedProducts') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.restaurantOverview.actions') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in user.restaurants" :key="u.p_id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ u.p_id }}
                    </th>
                    <td class="px-6 py-4">
                        {{ u.name }}
                    </td>
                    <td class="px-6 py-4">
                        {{ u.manager_id }}
                    </td>
                    <td class="px-6 py-4">
                        {{ u.assigned_products.length }}
                    </td>
                    <td class="px-6 py-4">
                        <button class="appButton" @click="user.editUser(u.p_id)">{{ $t('menu.settings.page.restaurantOverview.edit') }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <button class="appButton mt-2" @click="user.addEmployee()">{{ $t('menu.settings.page.restaurantOverview.add') }}</button>
    </div>
    
    <h2>{{ $t('menu.settings.page.productOverview.header') }}</h2>
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.productOverview.id') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.productOverview.name') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.productOverview.category') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('menu.settings.page.productOverview.actions') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="u in user.products" :key="u.p_id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ u.product_id }}
                    </th>
                    <td class="px-6 py-4">
                        {{ u.name }}
                    </td>
                    <td class="px-6 py-4">
                        {{ u.category_p_id }}
                    </td>
                    <td class="px-6 py-4">
                        <button class="appButton" @click="user.editUser(u.p_id)">{{ $t('menu.settings.page.productOverview.edit') }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <button class="appButton mt-2" @click="user.addEmployee()">{{ $t('menu.settings.page.productOverview.add') }}</button>
    </div>

</template>

<script>
import { useSettings } from '@/store/user'
// import { storeToRefs } from 'pinia'
import mixins from '@/components/mixins';

export default {
    mixins: [mixins],
    setup() {
        const user = useSettings();

        user.updateData();

        // const { users } = storeToRefs(user)
        
        // user.getEmployees();

        return { user }
    },
    components: {
        
    },
    methods: {
        toogleAdminName(id){
            switch(id){
                case 1:
                    return 'Admin';
                case 2:
                    return 'Manager';
                case 3:
                    return 'Employee';
            
        }
    }
}
}
</script>